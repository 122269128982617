import { Component, Input, OnInit } from "@angular/core";
import { ReportesDespachosService } from "../services/reportes-despachos.service";
import * as moment from "moment";

@Component({
  selector: "app-rpt-informe-movimientos-totales",
  templateUrl: "./rpt-informe-movimientos-totales.component.html",
  styleUrls: [],
})
export class RptInformeMovimientosTotalesComponent implements OnInit {
  @Input() data_rpt = [];
  @Input() tituloReporte = "";
  @Input() chkBus = false;
  @Input() _bus = "";
  @Input() despacho_liquidacion = "";
  @Input() _cerrada;
  FechaDesde = "";
  FechaHasta = "";
  loading: boolean = false;
  total_retardos: number = 0;
  total_contingencia: number = 0;
  total_bioseguridad: number = 0;
  total_gps: number = 0;
  total_vias: number = 0;
  total_ss_conductor: number = 0;
  total_planilla: number = 0;
  total_general: number = 0;
  total_relevante: number = 0;
  total_abono_ss: number = 0;
  total_abono_ss_relevante: number = 0;
  total_minutos: number = 0;
  gtotal_ahorro_propietario: number = 0;
  total_abono_credito: number = 0;
  total_sanciones: number = 0;
  total_ahorro_empresa: number = 0;

  constructor(private _serviceReportesDespachos: ReportesDespachosService) {
    this._serviceReportesDespachos.EventDatosInformeMovimientosTotales$.subscribe(
      async (resp: any) => {
        this.data_rpt = resp;
        /* this.data_rpt.forEach((item) => {
          item.numero = parseInt(item.numero);
        }); */
        this.get_Total_Ahorro_Empresa();
        this.get_Total_Ahorro_Propietario();
        this.get_Total_Abono_SS();
        this.get_Total_Abono_SS_Relevante();
        this.get_Total_Sanciones();
        this.get_Total_Relevante();
        this.get_Total_Retardos();
        this.get_Total_Contingencia();
        this.get_Total_Bioseguridad();
        this.get_Total_GPS();
        this.get_Total_Vias();
        this.get_Total_SS_Conductor();
        this.get_Total_Planilla();
        this.get_Total_General();
      }
    );

    this._serviceReportesDespachos.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportesDespachos.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });
  }

  ngOnInit() {}

  async get_Total_Ahorro_Propietario() {
    this.gtotal_ahorro_propietario = this.data_rpt
      .map((m) => m.total_ahorro_propietario)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Ahorro_Empresa() {
    this.total_ahorro_empresa = this.data_rpt
      .map((m) => m.total_ahorro_empresa)
      .reduce((acc, curr) => +acc + +curr, 0);
    console.log(this.total_ahorro_empresa);
  }

  async get_Total_Sanciones() {
    this.total_sanciones = this.data_rpt
      .map((m) => m.total_sanciones)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Abono_SS() {
    this.total_abono_ss = this.data_rpt
      .map((m) => m.total_abono_ss)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Abono_SS_Relevante() {
    this.total_abono_ss_relevante = this.data_rpt
      .map((m) => m.total_abono_ss_relevante)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Relevante() {
    this.total_relevante = this.data_rpt
      .map((m) => m.total_relevante)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Retardos() {
    this.total_retardos = this.data_rpt
      .map((m) => m.total_retardos)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Contingencia() {
    this.total_contingencia = this.data_rpt
      .map((m) => m.total_fondo_contingencia)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Bioseguridad() {
    this.total_bioseguridad = this.data_rpt
      .map((m) => m.total_bioseguridad)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_GPS() {
    this.total_gps = this.data_rpt
      .map((m) => m.total_gps)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Vias() {
    this.total_vias = this.data_rpt
      .map((m) => m.total_vias)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_SS_Conductor() {
    this.total_ss_conductor = this.data_rpt
      .map((m) => m.total_ss_conductor)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_Planilla() {
    this.total_planilla = this.data_rpt
      .map((m) => m.total_planilla)
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  async get_Total_General() {
    this.total_general = this.data_rpt
      .map((m) => parseFloat(m.total_item))
      .reduce((acc, curr) => +acc + +curr, 0);
  }

  exportAsXLSX() {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `INFORME_MOVIMIENTOS_TOTALES_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY") +
      " | Despacho Liquidación: " +
      this.despacho_liquidacion;

    this.data_rpt = this.data_rpt.map((x, index) => ({
      item: index + 1,
      documento_propietario: parseInt(x.documento_propietario),
      socio_documento: parseInt(x.socio_documento),
      bus: parseInt(x.bus),
      sucursal_contable: parseInt(x.sucursal_contable),
      propietario_nombres: x.propietario_nombres,
      socio_nombres: x.socio_nombres,
      total_ahorro_empresa: parseFloat(x.total_ahorro_empresa),
      total_ahorro_propietario: parseFloat(x.total_ahorro_propietario),
      total_abono_ss: parseFloat(x.total_abono_ss),
      total_abono_ss_relevante: parseFloat(x.total_abono_ss_relevante),
      total_relevante: parseFloat(x.total_relevante),
      total_sanciones: parseFloat(x.total_sanciones),
      total_retardos: parseFloat(x.total_retardos),
      total_fondo_contingencia: parseFloat(x.total_fondo_contingencia),
      total_bioseguridad: parseFloat(x.total_bioseguridad),
      total_gps: parseFloat(x.total_gps),
      total_vias: parseFloat(x.total_vias),
      total_ss_conductor: parseFloat(x.total_ss_conductor),
      total_planilla: parseFloat(x.total_planilla),
      total_item: parseFloat(x.total_item),
    }));

    this._serviceReportesDespachos.downloadFile_InformeMovimientosTotal(
      this.data_rpt,
      filename,
      periodo
    );
  }
}
