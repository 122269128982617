import { Injectable } from "@angular/core";
import { URL_API } from "../../../../config/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Planilla } from "src/app/models/planillas.model";
import { of } from "rxjs";
import Swal from "sweetalert2";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducers";
import { cargarPlanillasMasterByPlanilla_id } from "../store/actions";

@Injectable({
  providedIn: "root",
})
export class PlanillasService {
  httpOptions = {};
  private url = URL_API;

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  getListar(pagina: string, limit: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_listar/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getListar_Registradas(pagina: string, limit: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_listar_registradas/${pagina}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getListar_Registradasbyperiodo(desde: string, hasta: string, limit: number) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_listar_registradasbyperiodo/${desde}/${hasta}/${limit}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getListar_RegistradasByUser_id(
    pagina: string,
    limit: string,
    user_id: number
  ) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_listar_registradasbyuser_id/${pagina}/${limit}/${user_id}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getListar_RegistradasByUserPeriodo(
    desde: string,
    hasta: string,
    limit: string,
    user_id: number
  ) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_listar_registradasbyuserperiodo/${desde}/${hasta}/${limit}/${user_id}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getPlanillas_byestado(pagina: number, limit: number, estado: number) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_planillas_byestado/${pagina}/${limit}/${estado}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getRegistradaById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planillas/get_registradaById/${id}`, this.httpOptions)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getRetardos_LiquidacionById(planilla_id: number) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_retardos_liquidacionbyid/${planilla_id}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  /* getPlanilla_LlegadaById(id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_planilla_llegadabyid/${id}`,
        this.httpOptions
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
 */

  getById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planillas/get_byid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  get_consecutivoexiste(consec: number) {
    this.loadAuth();
    const url = URL_API + "/planillas/get_consecutivoexiste/" + consec;
    return this.http.get(url, this.httpOptions);
  }

  get_planilladia_byVehiculoId(vehiculo_id: number, fplanilla: string) {
    this.loadAuth();
    const url =
      URL_API +
      "/planillas/get_planilladia_existe/" +
      vehiculo_id +
      "/" +
      fplanilla;
    return this.http.get(url, this.httpOptions);
  }

  getByplanillarutadespacho_id(planillarutadespacho_id: string) {
    this.loadAuth();
    return this.http
      .get(
        `${this.url}/planillas/get_byplanillarutadespacho_id/${planillarutadespacho_id}`
      )
      .pipe(map((resp) => resp["data"]));
  }

  get_planilla_recorridobyid(planilla_id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planillas/get_planilla_recorridobyid/${planilla_id}`)
      .pipe(map((resp) => resp["data"]));
  }

  get_buscar(pagina: string, limit: string, buscar: string) {
    this.loadAuth();
    const url =
      URL_API + "/planillas/get_buscar/" + pagina + "/" + limit + "/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  get_buscarbyperiodo(
    desde: string,
    hasta: string,
    limit: string,
    buscar: string
  ) {
    this.loadAuth();
    const url = `${URL_API}/planillas/get_buscarbyperiodo/${desde}/${hasta}/${limit}/${buscar}`;
    return this.http.get(url, this.httpOptions);
  }

  get_ultima_planilla_bybus(buscar: string) {
    this.loadAuth();
    const url = URL_API + "/planillas/get_ultima_planilla_bybus/" + buscar;
    return this.http.get(url, this.httpOptions);
  }

  getLiquidacionById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planillas/get_liquidacionbyid/${id}`)
      .pipe(map((resp) => resp["data"]));
  }

  get_conceptos_pagarById(id: string) {
    this.loadAuth();
    return this.http
      .get(`${this.url}/planillas/get_conceptos_pagar/${id}`)
      .pipe(map((resp) => resp));
  }

  create(model: any) {
    this.loadAuth();
    return this.http.post(
      `${this.url}/planillas/nuevo`,
      model,
      this.httpOptions
    );
  }

  update(id: string, model: Planilla) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/planillas/update/${id}`,
      model,
      this.httpOptions
    );
  }

  updateliquidacion(id: string, model: Planilla) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/planillas/updateliquidacion/${id}`,
      model,
      this.httpOptions
    );
  }

  update_cerrarplanilla(id: string, model: Planilla) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/planillas/update_cerrarplanilla/${id}`,
      model,
      this.httpOptions
    );
  }

  update_parametro(id: number, model: any) {
    this.loadAuth();
    return this.http.put(
      `${this.url}/planillas/update_parametro/${id}`,
      model,
      this.httpOptions
    );
  }

  getdelete(id: string) {
    this.loadAuth();
    return this.http.delete(
      `${this.url}/planillas/delete/${id}`,
      this.httpOptions
    );
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
