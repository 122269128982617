import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { PlanillasService } from "../planillas/services/planillas.service";
import { Planilla } from "src/app/models/planillas.model";
import { EmpresaService } from "src/app/services/empresa.service";
import { Empresa } from "src/app/models/empresa.model";
import { QRCodeElementType, QRCodeErrorCorrectionLevel } from "angularx-qrcode";
import { BehaviorSubject, Subscription } from "rxjs";
import {
  MatKeyboardComponent,
  MatKeyboardRef,
} from "angular-onscreen-material-keyboard";
import { BlockUI, NgBlockUI } from "ng-block-ui";

const KeyboardOptions = {
  layout: "number",
  entertext: "Enter",
};

@Component({
  selector: "app-monitor-planillas",
  templateUrl: "./monitor-planillas.component.html",
  styleUrls: ["./monitor-planillas.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class MonitorPlanillasComponent implements OnInit, AfterViewInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  @ViewChild("txtsearch", { static: false })
  txtsearch: ElementRef<HTMLInputElement>;
  form: FormGroup;
  _search: any = "";
  data_conceptos = [];
  data_retardos = [];
  rutasAgrupadas = [];
  data_planilla: Planilla;
  data_empresa: Empresa;
  anio: number = new Date().getFullYear();
  data: any;
  isExist: boolean = true;

  public allowEmptyString: boolean;
  public alt: string;
  public ariaLabel: string;
  public colorDark: string;
  public colorLight: string;
  public cssClass: string;
  public elementType: QRCodeElementType;
  public errorCorrectionLevel: QRCodeErrorCorrectionLevel;
  public imageSrc?: string;
  public imageHeight?: number;
  public imageWidth?: number;
  public margin: number;
  // public qrdata: string;
  public scale: number;
  public title: string;
  public width: number;
  public showImage: boolean;

  private _enterSubscription: Subscription;
  private _closeSubscription: Subscription;
  private _submittedForms = new BehaviorSubject<
    { control: string; value: string }[][]
  >([]);
  private _keyboardRef: MatKeyboardRef<MatKeyboardComponent>;

  constructor(
    private fb: FormBuilder,
    private _servicePlanillas: PlanillasService,
    private _serviceEmpresa: EmpresaService,
    @Inject(LOCALE_ID) public locale
  ) {}

  ngAfterViewInit(): void {
    this.txtsearch.nativeElement.focus();
  }

  ngOnInit() {
    this._serviceEmpresa.getbyid("1").subscribe((resp: any) => {
      this.data_empresa = resp.data[0];
    });
    this.form = this.fb.group({
      search: ["", Validators.required],
    });
  }

  get_planilla(value: any) {
    this._search = value;

    if (value.length > 0) {
      this.isExist = true;
      this.blockUI.start("Consultando datos. Espere un momento...");
      this._servicePlanillas.getRegistradaById(value).subscribe((resp: any) => {
        this.data_planilla = resp.data;

        if (!resp.data) {
          this.isExist = false;
          this.form.reset();
          this.blockUI.stop();
        } else {
          // this.qrdata = this.data_planilla.planilla_id.toString();
          console.log(resp.data);
          this.isExist = true;
          this._servicePlanillas
            .get_conceptos_pagarById(value)
            .subscribe((resp: any) => {
              this.data_conceptos = resp.data;
              this._servicePlanillas
                .getRetardos_LiquidacionById(value)
                .subscribe((resp: any) => {
                  this.data_retardos = resp.data;
                  console.log(this.data_retardos);
                  this.agruparRutas();
                  this.blockUI.stop();
                  this.form.reset();
                });
            });
        }
      });
    }
  }

  get_Total_Planilla() {
    let total: number = this.data_conceptos
      .filter((f) => f.visible == 1)
      .reduce((acum: number, items: { valor: number }) => {
        return acum + +items.valor;
      }, 0);
    return total;
  }

  get_Total_Retardos() {
    let total: number = this.data_retardos.reduce(
      (acum: number, items: { retardo: number }) => {
        return acum + +items.retardo;
      },
      0
    );
    return total;
  }

  closeCurrentKeyboard() {
    if (this._keyboardRef) {
      this._keyboardRef.dismiss();
    }

    if (this._enterSubscription) {
      this._enterSubscription.unsubscribe();
    }

    if (this._closeSubscription) {
      this._closeSubscription.unsubscribe();
    }
  }

  agruparRutas() {
    this.rutasAgrupadas = this.data_retardos.reduce((acc, item) => {
      const ruta = item.group_ruta_horasalida;
      if (!acc[ruta]) {
        acc[ruta] = [];
      }
      acc[ruta].push({
        lugarcontrol_nombre: item.lugarcontrol_nombre,
        tiempo_paso: item.tiempo_paso,
        tiempo_registro: item.tiempo_registro,
        retardo: item.retardo,
      });
      return acc;
    }, {});
  }
}
